@import "@core/scss/core.scss";
@import "./assets/scss/styles";

p,
label {
  color: #232323;
  font-size: 16px;
}

.no-scroll {
  overflow: hidden;
}
