a {
  &:focus {
    outline: none;
  }
}

a:not([href]) {
  color: inherit;
  text-decoration: none;

  @include hover() {
    color: inherit;
    text-decoration: none;
  }
}
